import { Page } from "../../Components/pages/objects/page";

export const MenuItems: NonNullable<Page['header']['menu']>['links'] = [
    {
        type: 'element',
        content: [
            {
                locale: 1,
                value: 'Destination'
            },
            {
                locale: 2,
                value: 'Destination'
            },
            {
                locale: 3,
                value: 'Destinazione'
            },
            {
                locale: 4,
                value: 'Destino'
            },
            {
                locale: 5,
                value: 'Destinació'
            },
            {
                locale: 6,
                value: 'Ziel'
            },
            {
                locale: 7,
                value: 'Bestemming'
            },
        ],
        url: '/:urlName/destinations',
        icon: 'Map'
    },
    {
        type: 'parent',
        content: [
            {
                locale: 1,
                value: 'Type de voyage'
            },
            {
                locale: 2,
                value: 'Type of trip'
            },
            {
                locale: 3,
                value: 'Tipo di viaggio'
            },
            {
                locale: 4,
                value: 'tipo de viaje'
            },
            {
                locale: 5,
                value: 'Tipus de viatge'
            },
            {
                locale: 6,
                value: 'Art der Reise'
            },
            {
                locale: 7,
                value: 'Soort reis'
            },
        ],
        children: [
            {
                type: 'element',
                content: [
                    {
                        locale: 1,
                        value: 'Séjours'
                    },
                    {
                        locale: 2,
                        value: 'Stays'
                    },
                    {
                        locale: 3,
                        value: 'Soggiorni'
                    },
                    {
                        locale: 4,
                        value: 'Corsé'
                    },
                    {
                        locale: 5,
                        value: 'Estades'
                    },
                    {
                        locale: 6,
                        value: 'Bleibt'
                    },
                    {
                        locale: 7,
                        value: 'Verblijven'
                    },
                ],
                url: '/:urlName/no-destination?type=packages',
            },
            {
                type: 'element',
                content: [
                    {
                        locale: 1,
                        value: 'Autotours'
                    },
                    {
                        locale: 2,
                        value: 'Self-drive tours'
                    },
                    {
                        locale: 3,
                        value: 'Tour senza guida'
                    },
                    {
                        locale: 4,
                        value: 'Tours sin conductor'
                    },
                    {
                        locale: 5,
                        value: 'Tours amb autoconducció'
                    },
                    {
                        locale: 6,
                        value: 'Selbstfahrertouren'
                    },
                    {
                        locale: 7,
                        value: 'Rondleidingen met eigen auto'
                    },
                ],
                url: '/:urlName/no-destination?type=autotours',
            },
            {
                type: 'element',
                content: [
                    {
                        locale: 1,
                        value: 'Circuits'
                    },
                    {
                        locale: 2,
                        value: 'Tours'
                    },
                    {
                        locale: 3,
                        value: 'Tour'
                    },
                    {
                        locale: 4,
                        value: 'Visitas guiadas'
                    },
                    {
                        locale: 5,
                        value: 'Tours'
                    },
                    {
                        locale: 6,
                        value: 'Touren'
                    },
                    {
                        locale: 7,
                        value: 'Rondleidingen'
                    },
                ],
                url: '/:urlName/no-destination?type=circuits',
            },
            {
                type: 'element',
                content: [
                    {
                        locale: 1,
                        value: 'Combinés'
                    },
                    {
                        locale: 2,
                        value: 'Combined'
                    },
                    {
                        locale: 3,
                        value: 'Combinato'
                    },
                    {
                        locale: 4,
                        value: 'Conjunto'
                    },
                    {
                        locale: 5,
                        value: 'Combinat'
                    },
                    {
                        locale: 6,
                        value: 'Kombiniert'
                    },
                    {
                        locale: 7,
                        value: 'Gecombineerd'
                    },
                ],
                url: '/:urlName/no-destination?type=combined',
            },
            {
                type: 'element',
                content: [
                    {
                        locale: 1,
                        value: 'Croisières'
                    },
                    {
                        locale: 2,
                        value: 'Cruises'
                    },
                    {
                        locale: 3,
                        value: 'Crociere'
                    },
                    {
                        locale: 4,
                        value: 'Cruceros'
                    },
                    {
                        locale: 5,
                        value: 'Creuers'
                    },
                    {
                        locale: 6,
                        value: 'Kreuzfahrten'
                    },
                    {
                        locale: 7,
                        value: 'Cruises'
                    },
                ],
                url: '/:urlName/no-destination?type=cruises',
            },
        ],
        icon: 'Explore'
    },
    {
        type: 'parent',
        content: [
            {
                locale: 1,
                value: 'Voyages thématiques'
            },
            {
                locale: 2,
                value: 'Thematic trips'
            },
            {
                locale: 3,
                value: 'Viaggi tematici'
            },
            {
                locale: 4,
                value: 'Viajes temáticos'
            },
            {
                locale: 5,
                value: 'Viatges temàtics'
            },
            {
                locale: 6,
                value: 'Thematische Reisen'
            },
            {
                locale: 7,
                value: 'Thematische reizen'
            },
        ],
        children: 'trip-categories',
        icon: 'Luggage'
    },
    {
        type: 'event',
        name: 'new-tailor-made',
        content: [
            {
                locale: 1,
                value: 'Voyage sur mesure'
            },
            {
                locale: 2,
                value: 'Tailor-made trip'
            },
            {
                locale: 3,
                value: 'Viaggio su misura'
            },
            {
                locale: 4,
                value: 'Viaje a tu medida'
            },
            {
                locale: 5,
                value: 'Viatge a mida'
            },
            {
                locale: 6,
                value: 'Maßgeschneiderte Reise'
            },
            {
                locale: 7,
                value: 'Reis op maat'
            },
        ],
        icon: 'Signpost'
    },
    {
        type: 'element',
        content: [
            {
                locale: 1,
                value: 'Brochures'
            },
            {
                locale: 2,
                value: 'Brochures'
            },
            {
                locale: 3,
                value: 'Brochure'
            },
            {
                locale: 4,
                value: 'Folletos'
            },
            {
                locale: 5,
                value: 'Fullets'
            },
            {
                locale: 6,
                value: 'Broschüren'
            },
            {
                locale: 7,
                value: 'Folders'
            },
        ],
        url: '/:urlName/booklets',
        icon: 'MenuBook',
        target: '_blank'
    },
    {
        type: 'element',
        content: [
            {
                locale: 1,
                value: 'Nous contacter'
            },
            {
                locale: 2,
                value: 'Contact us'
            },
            {
                locale: 3,
                value: 'Contattaci'
            },
            {
                locale: 4,
                value: 'Contáctenos'
            },
            {
                locale: 5,
                value: 'Contacta amb nosaltres'
            },
            {
                locale: 6,
                value: 'Kontaktieren Sie uns'
            },
            {
                locale: 7,
                value: 'Neem contact met ons op'
            },
        ],
        url: '/:urlName/our-contacts',
        icon: 'PersonSearchRounded',
        target: '_blank'
    },
    {
        type: 'element',
        content: [
            {
                locale: 1,
                value: 'Entre nous'
            },
            {
                locale: 2,
                value: 'Between us'
            },
            {
                locale: 3,
                value: 'Tra di noi'
            },
            {
                locale: 4,
                value: 'entre nosotros'
            },
            {
                locale: 5,
                value: 'Entre nosaltres'
            },
            {
                locale: 6,
                value: 'Unter uns'
            },
            {
                locale: 7,
                value: 'Tussen ons'
            },
        ],
        url: '/:urlName/between-us',
        icon: 'BctNautil',
        target: '_blank'
    },
]