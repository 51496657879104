export function getHeaderContent() {
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    let data = null;
    let domain_name = window.location.host.replace("www.", "").replace("-cms", "").replace(".com", ".fr");
    let is_bonogo = domain_name.includes("bonogo");
    if (is_bonogo) {
        try {
            data = require(`../hardCodedHeader/bonogo`).default;
        } catch (error) {
            console.error(error);
        }
    } else if (quotationCode) {
        try {
            data = require(`../hardCodedHeader/${quotationCode}`).default;
        } catch (error) {
            console.error(error);
        }
    }
    
    const defaultHeader = require(`../hardCodedHeader/beachcomber`).default;

    return data ?? {
        ...defaultHeader,
        logo: undefined,
        transparentLogo: undefined
    };
}