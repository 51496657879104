import { isObject } from 'lodash';
import { ADD_CUSTOM_PICTURES, SET_PICTURE } from '../ActionTypes/Pictures';
import {
    DELETE_CUSTOM_PICTURE,
    DELETE_ALL_CUSTOM_PICTURE,
    ADD_PROVIDER_PICTURES,
    ADD_CUSTOM_PICTURES_ORDER,
    TOGGLE_PICTURE_ACTIVATED,
    TOGGLE_PICTURE_SELECT_ALL,
    TOGGLE_PICTURE_UNSELECT_ALL,
    UPDATE_PICTURE_ORDER
} from '../ActionTypes/Cart';
import ConvertPrice from '../Components/Cart/Functions/Manual/ConvertPrice';
import { addProviderDates } from '../Components/CartMaterial/utils/addProviderDates';

const initial_state = {
    manual_item_list: [],
    step: 1,
    cart_cropper: false,
    gallery_pic: false,
    active_page_gallery: 1,
    infos_reloaded: [],
    err: false,
    refund_condition: false,

    update_type: null,
    update_loading: false,
    update_error: false,
    update_infos: null,
    has_accommodation_step: false,
    has_cars: false,
    has_custom_transfer: false,
    has_flight: false,
    has_checked_items: false,
    run_next: false,
    show_cancelled_products: true,

    see_margin: false,
    booking_detail: null,
    price_detail: null,
    cancellation_detail: null,
    cancellation_currency: null,
    car_contract: null,
    car_to_search: null,
    to_book: [],
    flight_price_detail: null,
    full_trip_price: false,
    manual_product_geolocation: {
        address: '',
        position: {
            latitude: null,
            longitude: null
        }
    },
    manual_product_form: false,
    manual_product_id: null,
    manual_product_name: '',
    manual_product_type: 0,
    manual_product_destination_associated: -1,
    manual_product_provider_id: null,
    manual_product_provider_name: '',
    manual_product_groups: [],
    manual_product_booking_status: 'DEFAULT',
    manual_product_booking_ref: '',
    manual_product_start_date: null,
    manual_product_end_date: null,
    manual_product_address: '',
    manual_product_stars: 0,
    manual_product_cgos_reduction: false,
    manual_product_rooms: [{
        name: '',
        description_list: [],
        group: [],
        breakfast_included: false,
        lunch_included: false,
        dinner_included: false,
        all_inclusive: false
    }],
    manual_product_resort_fees: null,
    manual_product_resort_fees_currency: null,
    manual_product_flight_paste: null,
    manual_product_baggages: 0,
    manual_product_flight_taxes: null,
    manual_product_purchase_price: 0,
    manual_product_purchase_currency: 47,
    manual_product_margin_type: 'PER',
    manual_product_margin_value: 0,
    manual_product_tva_margin: 0,
    manual_product_price_id: null,
    manual_product_description: '',
    manual_product_cancellation_note: '',
    manual_product_no_refund: false,
    manual_product_note: '',
    manual_product_picture: null,
    manual_product_picture_modal: false,
    manual_product_start_hour: '00:00',
    manual_product_end_hour: '00:00',
    manual_product_poi: false,
    manual_product_address_pickup: '',
    manual_product_station_name_pickup: '',
    manual_product_address_return: '',
    manual_product_station_name_return: '',
    manual_product_vehicle_code: '',
    manual_product_one_way_fees: '',
    manual_product_one_way_fees_currency: null,
    manual_product_is_hidden_for_traveler_count_price: false,
    refresh_price: null,
    refresh_apply_to_margin_to_all: false,
    quotation_target: 'TO',
    quotation_type: 'DEFAULT',
    quotation_category_price_detail: false,
    quotation_product_price_detail: false,
    quotation_practical_information: false,
    quotation_without_prices: false,
    quotation_target_language: 'fr',
    quotation_cgv: false,
    need_recalc: false,
    global_margin: null,
    tva_value: 0,
    margin_value: 0,
    global_margin_value: null,
    total_accommodations: [],
    total_cars: [],
    total_pois: [],
    total_transfers: [],
    total_flights: [],
    total_manual_products: [],
    total_assistances: [],
    has_not_cancellable_items: 0,
    full_trip_flight_taxes: 0,
    full_trip_flight_purchase_price: 0,
    full_trip_flight_purchase_currency: 47,
    full_trip_flight_margin_type: 'PER',
    full_trip_flight_margin_value: 0,
    full_trip_terrestrial_purchase_price: 0,
    full_trip_terrestrial_purchase_currency: 47,
    full_trip_terrestrial_margin_type: 'PER',
    full_trip_terrestrial_margin_value: 0,

    custom_pictures: [],
    provider_pictures: [],
    order_pictures: [],

    assistance_type: 0,
    assistance_modal: false,
    assistance_package: 'Plus',
    assistance_option_pocket_wifi: false,
    pocket_wifi_pickup: '',
    pocket_wifi_physical_address: '',
    assistance_index: 0,
    assistance_pax: [],
    assistance_baggage_protection: false,
    assistance_pocket_wifi: false,
    assistance_prices: null,
    assistance_lead_pax: null,
    assistance_cart: [],
    assistance_travelers_type: [],

    update_date: false,
    filters_booked_items: false,
    filters_waiting_booked_items: false,
    filters_non_booked_items: false,
    filters_masked_items: false,
    filters_visible_items: false,
    filters_cancellable_items: false,
    filters_non_cancellable_items: false,

    package_modal: false,
    package_provider: null,
    price_type: "flight",
    package_price_flight: {
        purchase_price: 0,
        purchase_currency: {
            custom_currency: [],
            id: 47,
            international_name: "Euro",
            iso_code: "EUR",
            name: "Euro",
            symbol: "€",
            usd_factor: "0.84280100"
        },
        selling_price: 0,
        margin_value: 0,
        rate_type: "PER",
        type: "margin"
    },
    package_price_terrestrial: {
        purchase_price: 0,
        purchase_currency: {
            custom_currency: [],
            id: 47,
            international_name: "Euro",
            iso_code: "EUR",
            name: "Euro",
            symbol: "€",
            usd_factor: "0.84280100"
        },
        selling_price: 0,
        margin_value: 0,
        rate_type: "PER",
        type: "margin"
    },
    trip_package_price_flight: {
        purchase_price: 0,
        purchase_currency: {
            custom_currency: [],
            id: 47,
            international_name: "Euro",
            iso_code: "EUR",
            name: "Euro",
            symbol: "€",
            usd_factor: "0.84280100"
        },
        selling_price: 0,
        margin_value: 0,
        rate_type: "PER",
        type: "margin"
    },
    trip_package_price_terrestrial: {
        purchase_price: 0,
        purchase_currency: {
            custom_currency: [],
            id: 47,
            international_name: "Euro",
            iso_code: "EUR",
            name: "Euro",
            symbol: "€",
            usd_factor: "0.84280100"
        },
        selling_price: 0,
        margin_value: 0,
        rate_type: "PER",
        type: "margin"
    },
    flight_taxes: 0,
    purchase_currency_error_flight: false,
    purchase_currency_error_terrestrial: false,
    hasLockedItems: false,
    hasStackedItems: false,
    purchase_currency_error: false,
    selling_currency_error: false,
    automatic_products_open: false,
    package_provider_error: false,

    variant_modal: false,
    variant_locales: {},

    see_new_price: false,
    new_price_data: null,

    product_type_filters: [],
    new_manual_product_list: [],
    deleted_manual_product_list: []
};

const genericApplyToMargin = (type, refresh_price, value) => {
    let type_cpy = { ...refresh_price[type] };
    Object.keys(type_cpy).map(key => {
        let data = { ...type_cpy[key] };
        data.affect_to_margin = value;
        type_cpy[key] = data;
    });
    return type_cpy;
};

export default function reducer(state = initial_state, action) {
    let new_state = Object.assign({}, state);
    switch (action.type) {
        case "persist/REHYDRATE": {
            if (action.payload !== undefined && action.payload.cart !== undefined) {
                Object.keys(action.payload.cart).forEach(key => {
                    if (new_state[key] !== undefined) {
                        new_state[key] = action.payload.cart[key];
                    }
                });
            }
            return new_state;
        }
        case "FLUSH_REMOVE_STATE": {
            new_state = initial_state;
            return new_state;
        }
        case "CMS_FLUSH_REMOVE_STATE": {
            new_state.manual_item_list = [];
            return new_state;
        }
        case "SMALL_FLUSH": {
            new_state = initial_state;
            return new_state;
        }
        case "RECALC_SET_TYPE": {
            new_state.update_type = action.payload;
            new_state.update_loading = true;
            return new_state;
        }
        case "RECALC_SET_ERROR": {
            new_state.update_error = true;
            new_state.update_loading = false;
            return new_state;
        }
        case "RECALC_SET_INFOS": {
            new_state.update_infos = action.payload;
            if (new_state.update_type === "CHECK") {
                new_state.has_accommodation_step = false;
                if (new_state.update_infos.accommodation_step && Object.keys(new_state.update_infos.accommodation_step).length > 0) {
                    Object.keys(new_state.update_infos.accommodation_step).map(key => {
                        if (new_state.update_infos.accommodation_step[key].end_date_moved || new_state.update_infos.accommodation_step[key].invalid_accommodation_to_itinerary || new_state.update_infos.accommodation_step[key].reversed_destination || new_state.update_infos.accommodation_step[key].start_date_moved) {
                            new_state.has_accommodation_step = true;
                        }
                    });
                }
                new_state.has_cars = false;
                if (new_state.update_infos.cars && Object.keys(new_state.update_infos.cars).length > 0) {
                    Object.keys(new_state.update_infos.cars).map(key => {
                        if (new_state.update_infos.cars[key].car_extended/*|| new_state.update_infos.cars[key].end_date_moved || new_state.update_infos.cars[key].invalid_car_to_itinerary || new_state.update_infos.cars[key].reversed_destination || new_state.update_infos.cars[key].start_date_moved*/) {
                            new_state.has_cars = true;
                        }
                    });
                }
                new_state.has_custom_transfer = false;/*
                if (new_state.update_infos.custom_transfer && Object.keys(new_state.update_infos.custom_transfer).length > 0) {
                    Object.keys(new_state.update_infos.custom_transfer).map(key => {
                        if (new_state.update_infos.custom_transfer[key].end_date_moved || new_state.update_infos.custom_transfer[key].invalid_transfer_to_itinerary || new_state.update_infos.custom_transfer[key].reversed_destination || new_state.update_infos.custom_transfer[key].start_date_moved) {
                            new_state.has_custom_transfer = true;
                        }
                    });
                }*/
                new_state.has_flight = false;
                if (new_state.update_infos.flight && Object.keys(new_state.update_infos.flight).length > 0) {
                    Object.keys(new_state.update_infos.flight).map(key => {
                        // @TODO: reset this when flight recalculation works in api
                        // if (new_state.update_infos.flight[key].date_moved || new_state.update_infos.flight[key].del || new_state.update_infos.flight[key].recalc || new_state.update_infos.flight[key].replaced || new_state.update_infos.flight[key].reversed_destination) {
                        if (new_state.update_infos.flight[key].del || new_state.update_infos.flight[key].replaced || new_state.update_infos.flight[key].reversed_destination) {
                            new_state.has_flight = true;
                        }
                    });
                }
                new_state.has_checked_items = new_state.has_accommodation_step || new_state.has_cars || new_state.has_custom_transfer || new_state.has_flight;
            }
            new_state.update_loading = false;
            return new_state;
        }
        case "RECALC_TOGGLE_CARS_EXTENDED": {
            let update_infos = Object.assign({}, new_state.update_infos);
            let cars = Object.assign({}, update_infos.cars);
            let car = Object.assign({}, cars[action.payload.key]);
            car.car_extended = !car.car_extended;
            if (car.car_extended) {
                if (car.start_date !== car.new_start_date) {
                    car.start_date_moved = true;
                }
                if (car.end_date !== car.new_end_date) {
                    car.end_date_moved = true;
                }
            } else {
                car.start_date_moved = false;
                car.end_date_moved = false;
            }
            cars[action.payload.key] = car;
            update_infos.cars = cars;
            new_state.update_infos = update_infos;
            return new_state;
        }
        case "RECALC_TOGGLE_FLIGHT_RECALC": {
            let update_infos = Object.assign({}, new_state.update_infos);
            let flights = Object.assign({}, update_infos.flight);
            let flight = Object.assign({}, flights[action.payload.key]);
            flight.recalc = !flight.recalc;
            flights[action.payload.key] = flight;
            update_infos.flight = flights;
            new_state.update_infos = update_infos;
            return new_state;
        }
        case "RECALC_UPDATE_NIGHTS": {
            let update_infos = Object.assign({}, new_state.update_infos);
            let accommodations = Object.assign({}, update_infos.accommodation_step);
            let accommodation = Object.assign({}, accommodations[action.payload.key]);
            let rooms = [...accommodation.rooms];
            let modified = false;
            rooms.map((room, index_room) => {
                let room_cpy = Object.assign({}, room);
                if (modified) {
                    const old_diff = moment.utc(room_cpy.proposed_end_date).diff(moment.utc(room_cpy.proposed_start_date), "d");
                    room_cpy.proposed_start_date = rooms[index_room - 1].proposed_end_date;
                    room_cpy.proposed_end_date = moment.utc(room_cpy.proposed_start_date).add(old_diff, "d").format("YYYY-MM-DD");
                }
                if (room_cpy.id === action.payload.id) {
                    if (action.payload.nights === "DELETE") {
                        room_cpy.proposed_end_date = room_cpy.proposed_start_date;
                        room_cpy.deletion = true;
                    } else {
                        room_cpy.proposed_end_date = moment.utc(room_cpy.proposed_start_date).add(action.payload.nights, 'd').format("YYYY-MM-DD");
                        room_cpy.deletion = false;
                    }
                    modified = true;
                }
                rooms[index_room] = room_cpy;
            });
            accommodation.rooms = rooms;
            accommodations[action.payload.key] = accommodation;
            update_infos.accommodation_step = accommodations;
            new_state.update_infos = update_infos;
            return new_state;
        }
        case "RECALC_RESET": {
            new_state.update_type = null;
            new_state.update_loading = false;
            new_state.update_error = false;
            new_state.update_infos = null;
            new_state.has_accommodation_step = false;
            new_state.has_cars = false;
            new_state.has_custom_transfer = false;
            new_state.has_flight = false;
            new_state.has_checked_items = false;
            new_state.run_next = false;
            new_state.need_recalc = false;
            return new_state;
        }
        case "CART_SET_UPDATE_LOADING": {
            new_state.update_loading = action.payload;
            return new_state;
        }
        case "CART_SET_STEP": {
            new_state.step = action.payload.step;
            if (new_state.step !== 1) {
                //new_state.to_book = [];
                new_state.manual_product_form = false;
                new_state.manual_product_id = null;
                new_state.manual_product_name = '';
                new_state.manual_product_type = 0;
                new_state.manual_product_destination_associated = -1;
                new_state.manual_product_provider_id = null;
                new_state.manual_product_provider_name = '';
                new_state.manual_product_groups = [];
                new_state.manual_product_booking_status = 'DEFAULT';
                new_state.manual_product_booking_ref = '';
                new_state.manual_product_start_date = null;
                new_state.manual_product_end_date = null;
                new_state.manual_product_address = '';
                new_state.manual_product_stars = 0;
                new_state.manual_product_cgos_reduction = false;
                new_state.manual_product_rooms = [{
                    name: '',
                    description_list: [],
                    group: [],
                    breakfast_included: false,
                    lunch_included: false,
                    dinner_included: false,
                    all_inclusive: false
                }];
                new_state.manual_product_resort_fees = null;
                new_state.manual_product_resort_fees_currency = null;
                new_state.manual_product_flight_paste = null;
                new_state.manual_product_baggages = 0;
                new_state.manual_product_flight_taxes = null;
                new_state.manual_product_purchase_price = 0;
                new_state.manual_product_purchase_currency = 47;
                new_state.manual_product_margin_type = 'PER';
                new_state.manual_product_margin_value = 0;
                new_state.manual_product_tva_margin = 0;
                new_state.manual_product_price_id = null;
                new_state.manual_product_description = '';
                new_state.manual_product_cancellation_note = '';
                new_state.manual_product_no_refund = false;
                new_state.manual_product_note = '';
                new_state.manual_product_picture = null;
                new_state.quotation_target = 'TO';
                new_state.quotation_type = 'DEFAULT';
                new_state.quotation_category_price_detail = false;
                new_state.quotation_product_price_detail = false;
                new_state.quotation_practical_information = false;
                new_state.quotation_without_prices = false;
                new_state.quotation_cgv = false;
                new_state.manual_product_address_pickup = '';
                new_state.manual_product_station_name_pickup = '';
                new_state.manual_product_address_return = '';
                new_state.manual_product_station_name_return = '';
                new_state.manual_product_vehicle_code = '';
                new_state.manual_product_one_way_fees = '';
                new_state.manual_product_one_way_fees_currency = null;
                new_state.manual_product_is_hidden_for_traveler_count_price = false;
            }
            return new_state;
        }
        case "CART_TOGGLE_CART_CROPPER": {
            new_state.cart_cropper = action.payload.cart_cropper;
            return new_state;
        }
        case "CART_TOGGLE_GALLERY": {
            new_state.gallery_pic = action.payload.gallery_pic;
            return new_state;
        }
        case "CART_CHANGE_ACTIVE_PAGE_GALLERY": {
            new_state.active_page_gallery = action.payload.active_page_gallery;
            return new_state;
        }

        case "CART_ADD_MANUAL_ITEM": {
            let tmp_cart = new_state.manual_item_list.slice();
            tmp_cart.push(action.payload);
            //tmp_cart = [].concat(tmp_cart, action.payload.manual_item_list);
            let no_date_cart = [];
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].start_date === null) {
                    no_date_cart.push(tmp_cart[i]);
                    tmp_cart.splice(i, 1);
                }
            }
            let sorted_cart = tmp_cart.sort(function (a, b) {
                let x = a.start_date;
                let y = b.start_date;
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
            if (no_date_cart.length > 0) {
                sorted_cart = [].concat(sorted_cart, no_date_cart);
            }
            new_state.manual_item_list = sorted_cart;
            new_state.manual_product_form = false;
            new_state.manual_product_id = null;
            new_state.manual_product_name = '';
            new_state.manual_product_type = 0;
            new_state.manual_product_destination_associated = -1;
            new_state.manual_product_provider_id = null;
            new_state.manual_product_provider_name = '';
            new_state.manual_product_groups = [];
            new_state.manual_product_booking_status = 'DEFAULT';
            new_state.manual_product_booking_ref = '';
            new_state.manual_product_start_date = null;
            new_state.manual_product_end_date = null;
            new_state.manual_product_start_hour = '00:00';
            new_state.manual_product_end_hour = '00:00';
            new_state.manual_product_address = '';
            new_state.manual_product_stars = 0;
            new_state.manual_product_cgos_reduction = false;
            new_state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            new_state.manual_product_resort_fees = null;
            new_state.manual_product_resort_fees_currency = null;
            new_state.manual_product_flight_paste = null;
            new_state.manual_product_baggages = 0;
            new_state.manual_product_flight_taxes = null;
            new_state.manual_product_purchase_price = 0;
            new_state.manual_product_purchase_currency = 47;
            new_state.manual_product_margin_type = 'PER';
            new_state.manual_product_margin_value = 0;
            new_state.manual_product_tva_margin = 0;
            new_state.manual_product_price_id = null;
            new_state.manual_product_description = '';
            new_state.manual_product_cancellation_note = '';
            new_state.manual_product_no_refund = false;
            new_state.manual_product_note = '';
            new_state.manual_product_picture = null;
            return new_state;
        }
        case "CART_EDIT_MANUAL_ITEM": {
            let tmp_cart = new_state.manual_item_list.slice();
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].id === action.payload.id) {
                    tmp_cart[i] = action.payload;
                    break;
                }
            }
            let no_date_cart = [];
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].start_date === null) {
                    no_date_cart.push(tmp_cart[i]);
                    tmp_cart.splice(i, 1);
                }
            }
            let sorted_cart = tmp_cart.sort(function (a, b) {
                let x = a.start_date;
                let y = b.start_date;
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
            if (no_date_cart.length > 0) {
                sorted_cart = [].concat(sorted_cart, no_date_cart);
            }
            new_state.manual_item_list = sorted_cart;
            new_state.manual_product_form = false;
            new_state.manual_product_id = null;
            new_state.manual_product_name = '';
            new_state.manual_product_type = 0;
            new_state.manual_product_destination_associated = -1;
            new_state.manual_product_provider_id = null;
            new_state.manual_product_provider_name = '';
            new_state.manual_product_groups = [];
            new_state.manual_product_booking_status = 'DEFAULT';
            new_state.manual_product_booking_ref = '';
            new_state.manual_product_start_date = null;
            new_state.manual_product_end_date = null;
            new_state.manual_product_start_hour = '00:00';
            new_state.manual_product_end_hour = '00:00';
            new_state.manual_product_address = '';
            new_state.manual_product_stars = 0;
            new_state.manual_product_cgos_reduction = false;
            new_state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            new_state.manual_product_resort_fees = null;
            new_state.manual_product_resort_fees_currency = null;
            new_state.manual_product_flight_paste = null;
            new_state.manual_product_baggages = 0;
            new_state.manual_product_flight_taxes = null;
            new_state.manual_product_purchase_price = 0;
            new_state.manual_product_purchase_currency = 47;
            new_state.manual_product_margin_type = 'PER';
            new_state.manual_product_margin_value = 0;
            new_state.manual_product_tva_margin = 0;
            new_state.manual_product_price_id = null;
            new_state.manual_product_description = '';
            new_state.manual_product_cancellation_note = '';
            new_state.manual_product_no_refund = false;
            new_state.manual_product_note = '';
            new_state.manual_product_picture = null;
            new_state.manual_product_address_pickup = '';
            new_state.manual_product_station_name_pickup = '';
            new_state.manual_product_address_return = '';
            new_state.manual_product_station_name_return = '';
            new_state.manual_product_vehicle_code = '';
            new_state.manual_product_one_way_fees = '';
            new_state.manual_product_one_way_fees_currency = null;
            new_state.manual_product_is_hidden_for_traveler_count_price = false;
            return new_state;
        }

        case "CART_SET_MANUAL_CART": {
            new_state.manual_item_list = action.payload.manual_cart;
            return new_state;
        }
        case "CART_SET_INFO_RELOADED": {
            new_state.infos_reloaded = action.payload.infos_reloaded;
            new_state.update_type = action.payload.type;
            return new_state;
        }

        case "CART_SET_MANUAL_MARGIN_EDIT": {
            let cart = new_state.manual_item_list.slice();
            let copy_cart = [];
            cart.map((item, item_index) => {
                if (item_index === action.payload.index) {
                    let copy_item = Object.assign({}, item);
                    copy_item.edit_margin = action.payload.edit;
                    item = copy_item;
                }
                copy_cart.push(item);
            });
            new_state.manual_item_list = copy_cart;
            return new_state;
        }
        case "CART_EDIT_MANUAL_CART_BY_ID": {
            let copy_cart = new_state.manual_item_list.slice();
            for (let i = 0; i < copy_cart.length; i++) {
                if (copy_cart[i].id === action.payload.item.id) {
                    copy_cart[i] = action.payload.item;
                    break;
                }
            }
            new_state.manual_item_list = copy_cart;
            return new_state;
        }
        case "CART_EDIT_MANUAL_CART": {
            let tmp_cart = new_state.manual_item_list.slice();
            tmp_cart[action.payload.index] = action.payload.item;
            let no_date_cart = [];

            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].start_date === null) {
                    no_date_cart.push(tmp_cart[i]);
                    tmp_cart.splice(i, 1);
                }
            }
            let sorted_cart = tmp_cart.sort(function (a, b) {
                let x = a.start_date;
                let y = b.start_date;
                return ((x < y) ? -1 : ((x > y) ? 1 : 0)) * 1;
            });

            if (no_date_cart.length > 0) {
                sorted_cart = [].concat(sorted_cart, no_date_cart);
            }
            new_state.manual_item_list = sorted_cart;
            return new_state;
        }
        case "CART_TOOGLE_EDIT_MANUAL_CART": {
            let tmp_cart = new_state.manual_item_list.slice();
            let copy_manual_cart = [];
            tmp_cart.map((product, index_product) => {
                if (index_product === action.payload.index) {
                    let copy_product = Object.assign({}, product);
                    copy_product.edit = !copy_product.edit;
                    product = copy_product;
                }
                copy_manual_cart.push(product);
            });
            new_state.manual_item_list = copy_manual_cart;
            return new_state;
        }
        case "CART_REMOVE_FROM_MANUAL_ITEM": {
            let cart = new_state.manual_item_list.slice();
            cart.splice(action.payload.index, 1);
            new_state.manual_item_list = cart;
            return new_state;
        }
        case "CART_TOGGLE_REFUND_CONDITION": {
            new_state.refund_condition = !new_state.refund_condition;
            return new_state;
        }
        case "CART_SET_ERROR": {
            new_state.err = action.payload.err;
            return new_state;
        }
        case "CART_TOGGLE_SHOW_CANCELLED_PRODUCTS": {
            new_state.show_cancelled_products = !new_state.show_cancelled_products;
            return new_state;
        }
        case 'CART_LEAVE_CART': {
            new_state.see_margin = false;
            new_state.booking_detail = null;
            new_state.price_detail = null;
            new_state.cancellation_detail = null;
            new_state.cancellation_currency = null;
            new_state.car_contract = null;
            new_state.car_to_search = null;
            new_state.flight_price_detail = null;
            new_state.manual_product_form = false;
            new_state.manual_product_id = null;
            new_state.manual_product_name = '';
            new_state.manual_product_type = 0;
            new_state.manual_product_destination_associated = -1;
            new_state.manual_product_provider_id = null;
            new_state.manual_product_provider_name = '';
            new_state.manual_product_groups = [];
            new_state.manual_product_booking_status = 'DEFAULT';
            new_state.manual_product_booking_ref = '';
            new_state.manual_product_start_date = null;
            new_state.manual_product_end_date = null;
            new_state.manual_product_start_hour = '00:00';
            new_state.manual_product_end_hour = '00:00';
            new_state.manual_product_address = '';
            new_state.manual_product_stars = 0;
            new_state.manual_product_cgos_reduction = false;
            new_state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            new_state.manual_product_resort_fees = null;
            new_state.manual_product_resort_fees_currency = null;
            new_state.manual_product_flight_paste = null;
            new_state.manual_product_baggages = 0;
            new_state.manual_product_flight_taxes = null;
            new_state.manual_product_purchase_price = 0;
            new_state.manual_product_purchase_currency = 47;
            new_state.manual_product_margin_type = 'PER';
            new_state.manual_product_margin_value = 0;
            new_state.manual_product_tva_margin = 0;
            new_state.manual_product_price_id = null;
            new_state.manual_product_description = '';
            new_state.manual_product_cancellation_note = '';
            new_state.manual_product_no_refund = false;
            new_state.manual_product_note = '';
            new_state.manual_product_picture = null;
            new_state.refresh_price = null;
            new_state.refresh_apply_to_margin_to_all = false;
            new_state.quotation_target = 'TO';
            new_state.quotation_type = 'DEFAULT';
            new_state.quotation_category_price_detail = false;
            new_state.quotation_product_price_detail = false;
            new_state.quotation_practical_information = false;
            new_state.quotation_without_prices = false;
            new_state.quotation_target_language = 'fr';
            new_state.quotation_cgv = false;
            new_state.need_recalc = false;
            new_state.global_margin = null;
            new_state.global_margin_value = null;
            new_state.total_accommodations = [];
            new_state.total_cars = [];
            new_state.total_pois = [];
            new_state.total_transfers = [];
            new_state.total_flights = [];
            new_state.total_manual_products = [];
            new_state.total_assistances = [];
            new_state.manual_product_poi = false;
            new_state.manual_product_address_pickup = '';
            new_state.manual_product_station_name_pickup = '';
            new_state.manual_product_address_return = '';
            new_state.manual_product_station_name_return = '';
            new_state.manual_product_vehicle_code = '';
            new_state.manual_product_one_way_fees = '';
            new_state.manual_product_one_way_fees_currency = null;
            new_state.manual_product_is_hidden_for_traveler_count_price = false;
            return new_state;
        }
        case 'CART_EDIT_MANUAL_PRODUCT': {
            const { manual_product, user, quotation_code, currency_list } = action.payload;
            if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < manual_product.prices.length; i++) {
                    if (manual_product.prices[i].master_price) {
                        new_state.manual_product_purchase_price = manual_product.prices[i].purchase_price;
                        new_state.manual_product_purchase_currency = manual_product.prices[i].purchase_currency;
                        new_state.manual_product_margin_type = manual_product.prices[i].custom_rate_type;
                        new_state.manual_product_margin_value = manual_product.prices[i].custom_value;
                        new_state.manual_product_price_id = manual_product.prices[i].id;
                        break;
                    }
                }
            } else {
                for (let i = 0; i < manual_product.prices.length; i++) {
                    if (manual_product.prices[i].owner === user.client) {
                        new_state.manual_product_purchase_price = manual_product.prices[i].purchase_price;
                        new_state.manual_product_purchase_currency = manual_product.prices[i].purchase_currency;
                        new_state.manual_product_margin_type = manual_product.prices[i].custom_rate_type;
                        new_state.manual_product_margin_value = manual_product.prices[i].custom_value;
                        new_state.manual_product_price_id = manual_product.prices[i].id;
                        break;
                    }
                }
            }
            if (manual_product.product_type === 22) {
                for (let i = 0; i < manual_product.prices.length; i++) {
                    if (manual_product.prices[i].is_tva) {
                        new_state.manual_product_tva_margin = manual_product.prices[i].selling_price;
                        break;
                    }
                }
            }
            new_state.manual_product_form = true;
            new_state.manual_product_geolocation = {
                address: manual_product.address ?? '',
                position: {
                    latitude: manual_product.latitude ?
                        parseFloat(manual_product.latitude) :
                        null,
                    longitude: manual_product.longitude ?
                        parseFloat(manual_product.longitude) :
                        null
                }
            };
            new_state.manual_product_id = manual_product.id;
            new_state.manual_product_name = manual_product.name;
            new_state.manual_product_type = manual_product.flight_paste !== null && manual_product.flight_paste !== '' ? 61 : manual_product.product_type;
            new_state.manual_product_destination_associated = manual_product.step;
            new_state.manual_product_provider_id = manual_product.provider_id === null && manual_product.provider_name !== null ? null : manual_product.provider_id;
            new_state.manual_product_provider_name = manual_product.provider_name === null ? '' : manual_product.provider_name;
            new_state.manual_product_groups = manual_product.group_passenger !== null ? manual_product.group_passenger.travelers : [];
            new_state.manual_product_start_date = manual_product.start_date !== null ? moment.utc(manual_product.start_date) : manual_product.start_date;
            new_state.manual_product_start_hour = manual_product.start_date !== null ? moment.utc(manual_product.start_date).format("HH:mm") : '00:00';
            new_state.manual_product_end_date = manual_product.end_date !== null ? moment.utc(manual_product.end_date) : manual_product.end_date;
            new_state.manual_product_end_hour = manual_product.end_date !== null ? moment.utc(manual_product.end_date).format("HH:mm") : '00:00';
            new_state.manual_product_address = manual_product.address === null ? '' : manual_product.address;
            new_state.manual_product_stars = manual_product.stars;
            new_state.manual_product_cgos_reduction = manual_product.cgos_reduction;
            new_state.manual_product_rooms = manual_product.rooms;
            new_state.manual_product_rooms.map(room => {
                if (room.group_passenger !== null) {
                    room.group = room.group_passenger.travelers;
                } else {
                    room.group = [];
                }
            });
            new_state.manual_product_resort_fees = manual_product.resort_fees;
            new_state.manual_product_resort_fees_currency = manual_product.resort_fees_currency !== null ? currency_list.find(currency => currency.id === manual_product.resort_fees_currency) : null;
            new_state.manual_product_flight_paste = manual_product.flight_paste;
            new_state.manual_product_baggages = manual_product.nb_baggages;
            new_state.manual_product_flight_taxes = manual_product.taxes;
            new_state.manual_product_description = manual_product.description === null ? '' : manual_product.description;
            new_state.manual_product_cancellation_note = manual_product.cancel_condition_text === null ? '' : manual_product.cancel_condition_text;
            new_state.manual_product_no_refund = manual_product.no_refund;
            new_state.manual_product_note = manual_product.custom_information === null ? '' : manual_product.custom_information;
            new_state.manual_product_picture = manual_product.picture;
            new_state.manual_product_is_hidden_for_traveler_count_price = manual_product.is_hidden_for_traveler_count_price;
            if (manual_product.product_type === 12) {
                new_state.manual_product_poi = manual_product.poi_type;
            }
            if (manual_product.product_type === 11 && quotation_code === 'cercledesvoyages') {
                if (manual_product.is_hidden_for_traveler_count_price) {
                    new_state.manual_product_type = -1;
                }
            }
            if (manual_product.product_type === 2 || manual_product.product_type === 4) {
                new_state.manual_product_address_pickup = manual_product.address_pickup;
                new_state.manual_product_station_name_pickup = manual_product.station_name_pickup;
                new_state.manual_product_address_return = manual_product.address_return;
                new_state.manual_product_station_name_return = manual_product.station_name_return;
                new_state.manual_product_vehicle_code = manual_product.vehicle_code;
                new_state.manual_product_one_way_fees = manual_product.one_way_fees;
                new_state.manual_product_one_way_fees_currency = manual_product.one_way_fees_currency !== null ? currency_list.find(currency => currency.id === manual_product.one_way_fees_currency) : null;
            }
            return new_state;
        }


        case 'CART_TOGGLE_MARGIN': {
            new_state.see_margin = !new_state.see_margin;
            return new_state;
        }
        case 'CART_TOGGLE_PRICE_DETAIL': {
            new_state.price_detail = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_BOOKING_DETAIL': {
            new_state.booking_detail = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_CANCELLATION_DETAIL': {
            new_state.cancellation_detail = action.payload.room;
            new_state.cancellation_currency = action.payload.currency;
            return new_state;
        }
        case 'CART_TOGGLE_FOR_BOOKING': {
            let to_book_cpy = new_state.to_book.slice();
            if (action.payload.rooms !== undefined && action.payload.rooms.length !== 0) {
                action.payload.rooms.map(room => {
                    let found = false;
                    for (let i = 0; i < to_book_cpy.length; i++) {
                        if (room.id === to_book_cpy[i].id) {
                            to_book_cpy.splice(i, 1);
                            found = true;
                            break;
                        }
                    }
                    if (!found) {
                        to_book_cpy.push(room);
                    }
                });
            } else {
                let found = false;
                for (let i = 0; i < to_book_cpy.length; i++) {
                    if (action.payload.id === to_book_cpy[i].id) {
                        to_book_cpy.splice(i, 1);
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    to_book_cpy.push(action.payload);
                }
            }
            new_state.to_book = to_book_cpy;
            return new_state;
        }
        case 'CART_TOGGLE_CONTRACT_DETAIL': {
            new_state.car_contract = action.payload;
            return new_state;
        }
        case 'CART_CAR_TO_SEARCH': {
            new_state.car_to_search = action.payload;
            return new_state;
        }
        case 'ADD_ITEMS_TO_BOOK': {
            new_state.to_book = new_state.to_book.concat(action.payload);
            return new_state;
        }
        case 'EDIT_ITEM_TO_BOOK': {
            let to_book = new_state.to_book.slice();
            let new_to_book = [];

            to_book.map((product) => {
                if (
                    product.is_manual &&
                    product.product_type === 0
                ) {
                    const room = 'rooms' in action.payload.product ?
                        action.payload.product.rooms.find((room) => {
                            return room.id === product.id;
                        }) :
                        null;
                    if (room) {
                        new_to_book.push({
                            ...action.payload.product,
                            id: room.id,
                            product_id: action.payload.product.id,
                            group_passenger: room.group_passenger,
                            is_manual: true
                        });
                    } else {
                        new_to_book.push(product);
                    }
                } else if (product.id === action.payload.product.id) {
                    new_to_book.push(action.payload.product);
                } else {
                    new_to_book.push(product);
                }
            });
            new_state.to_book = new_to_book;
            return new_state;
        }
        case 'CART_SET_TO_BOOK': {
            new_state.to_book = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_FLIGHT_PRICE_DETAIL': {
            new_state.flight_price_detail = action.payload;
            return new_state;
        }
        case 'CART_FULL_TRIP_PRICE': {
            new_state.full_trip_price = !new_state.full_trip_price;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_PRICE': {
            return {
                ...state,
                full_trip_flight_purchase_price: action.payload.flight_purchase_price,
                full_trip_flight_purchase_currency: action.payload.flight_purchase_currency,
                full_trip_flight_margin_type: action.payload.flight_custom_rate_type,
                full_trip_flight_margin_value: action.payload.flight_custom_value,
                full_trip_flight_taxes: action.payload.flight_taxes,
                full_trip_terrestrial_purchase_price: action.payload.terrestrial_purchase_price,
                full_trip_terrestrial_purchase_currency: action.payload.terrestrial_purchase_currency,
                full_trip_terrestrial_margin_type: action.payload.terrestrial_custom_rate_type,
                full_trip_terrestrial_margin_value: action.payload.terrestrial_custom_value
            };
        }
        case 'CART_SET_FULL_TRIP_FLIGHT_PURCHASE_PRICE': {
            new_state.full_trip_flight_purchase_price = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_FLIGHT_PURCHASE_CURRENCY': {
            new_state.full_trip_flight_purchase_currency = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_FLIGHT_MARGIN_TYPE': {
            new_state.full_trip_flight_margin_type = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_FLIGHT_MARGIN_VALUE': {
            new_state.full_trip_flight_margin_value = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_FLIGHT_TAXES': {
            new_state.full_trip_flight_taxes = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_TERRESTRIAL_PURCHASE_PRICE': {
            new_state.full_trip_terrestrial_purchase_price = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_TERRESTRIAL_PURCHASE_CURRENCY': {
            new_state.full_trip_terrestrial_purchase_currency = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_TERRESTRIAL_MARGIN_TYPE': {
            new_state.full_trip_terrestrial_margin_type = action.payload;
            return new_state;
        }
        case 'CART_SET_FULL_TRIP_TERRESTRIAL_MARGIN_VALUE': {
            new_state.full_trip_terrestrial_margin_value = action.payload;
            return new_state;
        }
        case 'CART_CREATE_NEW_MANUAL_PRODUCT': {
            new_state.manual_product_form = true;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_REMOVE_FROM_CART_BY_ID': {
            let tmp_cart = new_state.manual_item_list.slice();
            for (let i = 0; i < tmp_cart.length; i++) {
                if (tmp_cart[i].id === action.payload) {
                    tmp_cart.splice(i, 1);
                    break;
                }
            }
            new_state.manual_item_list = tmp_cart;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_NAME': {
            new_state.manual_product_name = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_NAME_DESC': {
            new_state.manual_product_name = action.payload.name;
            new_state.manual_product_description = action.payload.description;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_TYPE': {
            new_state.manual_product_type = action.payload.value;
            new_state.manual_product_provider_id = null;
            new_state.manual_product_provider_name = '';
            new_state.manual_product_start_date = action.payload.start_date;
            new_state.manual_product_end_date = action.payload.end_date;
            new_state.manual_product_poi = false;
            new_state.manual_product_flight_taxes = null;
            if (action.payload.value === 21) {
                new_state.manual_product_margin_type = 'FIX';
            }
            if (new_state.manual_product_picture === null && action.payload.default_pictures !== null) {
                action.payload.default_pictures.map(picture => {
                    if ((picture.type === 'DEFAULT_EXCURSIONS' && (new_state.manual_product_type === 1 || new_state.manual_product_type === 12)) ||
                        (picture.type === 'DEFAULT_INSURANCES' && new_state.manual_product_type === 8) ||
                        (picture.type === 'DEFAULT_TRANSFERS' && new_state.manual_product_type === 4) ||
                        (picture.type === 'DEFAULT_TRAINS' && new_state.manual_product_type === 5)) {
                        new_state.manual_product_picture = picture;
                    }
                });
            }
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED': {
            new_state.manual_product_destination_associated = action.payload.value;
            if (new_state.manual_product_destination_associated === -1) {
                new_state.manual_product_start_date = null;
                new_state.manual_product_end_date = null;
            } else {
                for (let i = 0; i < action.payload.itinerary_list.length; i++) {
                    if (action.payload.itinerary_list[i].id === new_state.manual_product_destination_associated) {
                        new_state.manual_product_start_date = moment.utc(action.payload.itinerary_list[i].start_date);
                        new_state.manual_product_end_date = moment.utc(action.payload.itinerary_list[i].end_date);
                        break;
                    }
                }
            }
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_PROVIDER': {
            new_state.manual_product_provider_id = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_PROVIDER_NAME': {
            new_state.manual_product_provider_name = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_BOOKING_STATUS': {
            new_state.manual_product_booking_status = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_BOOKING_REF': {
            new_state.manual_product_booking_ref = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_DATES': {
            new_state.manual_product_start_date = action.payload.start_date;
            new_state.manual_product_end_date = action.payload.end_date;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_ADDRESS': {
            new_state.manual_product_address = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_STARS': {
            new_state.manual_product_stars = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_CGOS_REDUCTION': {
            new_state.manual_product_cgos_reduction = !new_state.manual_product_cgos_reduction;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_ROOMS_NB': {
            const size = action.payload;
            let rooms = [...new_state.manual_product_rooms];
            if (rooms.length < size) {
                while (rooms.length < size) {
                    rooms.push({
                        name: '',
                        description_list: [],
                        group: [],
                        breakfast_included: false,
                        lunch_included: false,
                        dinner_included: false,
                        all_inclusive: false
                    });
                }
            } else {
                rooms.splice(size - 1, rooms.length - size);
            }
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_ROOM_NAME': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload.index] };
            room.name = action.payload.value;
            rooms[action.payload.index] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_ADD_MANUAL_PRODUCT_ROOM_DESCRIPTION': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload] };
            room.description_list.push('');
            rooms[action.payload] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_ROOM_DESCRIPTION': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload.index_room] };
            room.description_list[action.payload.index_description] = action.payload.value;
            rooms[action.payload.index] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_DELETE_MANUAL_PRODUCT_ROOM_DESCRIPTION': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload.index_room] };
            room.description_list.splice(action.payload.index_description, 1);
            rooms[action.payload.index] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_BREAKFAST_INCLUDED': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload] };
            if (!room.all_inclusive) {
                room.breakfast_included = !room.breakfast_included;
            }
            rooms[action.payload] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_LUNCH_INCLUDED': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload] };
            if (!room.all_inclusive) {
                room.lunch_included = !room.lunch_included;
            }
            rooms[action.payload] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_DINER_INCLUDED': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload] };
            if (!room.all_inclusive) {
                room.dinner_included = !room.dinner_included;
            }
            rooms[action.payload] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_ALL_INCLUSIVE': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload] };
            room.all_inclusive = !room.all_inclusive;
            if (room.all_inclusive) {
                room.breakfast_included = false;
                room.lunch_included = false;
                room.dinner_included = false;
            }
            rooms[action.payload] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_TOGGLE_MANUAL_PRODUCT_ROOM_TRAVELER': {
            let rooms = [...new_state.manual_product_rooms];
            let room = { ...rooms[action.payload.index_room] };
            let has_traveler = false;
            for (let i = 0; i < room.group.length; i++) {
                if (room.group[i] === action.payload.traveler_id) {
                    room.group.splice(i, 1);
                    has_traveler = true;
                    break;
                }
            }
            if (!has_traveler) {
                for (let i = 0; i < rooms.length; i++) {
                    for (let j = 0; j < rooms[i].group.length; j++) {
                        if (rooms[i].group[j] === action.payload.traveler_id) {
                            rooms[i].group.splice(j, 1);
                            break;
                        }
                    }
                }
                room.group.push(action.payload.traveler_id);
            }
            rooms[action.payload.index_room] = room;
            new_state.manual_product_rooms = rooms;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_FLIGHT_PASTE': {
            new_state.manual_product_flight_paste = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_BAGGAGES': {
            new_state.manual_product_baggages = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_FLIGHT_TAXES': {
            new_state.manual_product_flight_taxes = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_RESORT_FEES': {
            new_state.manual_product_resort_fees = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_RESORT_FEES_CURRENCY': {
            new_state.manual_product_resort_fees_currency = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_PURCHASE_PRICE': {
            new_state.manual_product_purchase_price = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_PURCHASE_CURRENCY': {
            new_state.manual_product_purchase_currency = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_MARGIN_TYPE': {
            new_state.manual_product_margin_type = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_MARGIN_VALUE': {
            new_state.manual_product_margin_value = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_TVA_MARGIN': {
            new_state.manual_product_tva_margin = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_DESCRIPTION': {
            new_state.manual_product_description = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_CANCELLATION_NOTE': {
            new_state.manual_product_cancellation_note = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_NO_REFUND_CHANGE': {
            new_state.manual_product_no_refund = action.payload;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_NOTE': {
            new_state.manual_product_note = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_PICTURE': {
            new_state.manual_product_picture = action.payload;
            new_state.manual_product_picture_modal = false;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_START_HOUR': {
            new_state.manual_product_start_hour = action.payload;
            return new_state;
        }
        case 'CART_SET_MANUAL_PRODUCT_END_HOUR': {
            new_state.manual_product_end_hour = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_MANUAL_PRODUCT_PICTURE': {
            new_state.manual_product_picture_modal = !new_state.manual_product_picture_modal;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_CANCEL': {
            new_state.manual_product_form = false;
            new_state.manual_product_id = null;
            new_state.manual_product_name = '';
            new_state.manual_product_type = 0;
            new_state.manual_product_destination_associated = -1;
            new_state.manual_product_provider_id = null;
            new_state.manual_product_provider_name = '';
            new_state.manual_product_groups = [];
            new_state.manual_product_booking_status = 'DEFAULT';
            new_state.manual_product_booking_ref = '';
            new_state.manual_product_start_date = null;
            new_state.manual_product_end_date = null;
            new_state.manual_product_start_hour = '00:00';
            new_state.manual_product_end_hour = '00:00';
            new_state.manual_product_address = '';
            new_state.manual_product_stars = 0;
            new_state.manual_product_rooms = [{
                name: '',
                description_list: [],
                group: [],
                breakfast_included: false,
                lunch_included: false,
                dinner_included: false,
                all_inclusive: false
            }];
            new_state.manual_product_breakfast_included = false;
            new_state.manual_product_lunch_included = false;
            new_state.manual_product_diner_included = false;
            new_state.manual_product_resort_fees = null;
            new_state.manual_product_resort_fees_currency = null;
            new_state.manual_product_flight_paste = null;
            new_state.manual_product_baggages = 0;
            new_state.manual_product_flight_taxes = 0;
            new_state.manual_product_purchase_price = 0;
            new_state.manual_product_purchase_currency = 47;
            new_state.manual_product_margin_type = 'PER';
            new_state.manual_product_margin_value = 0;
            new_state.manual_product_tva_margin = 0;
            new_state.manual_product_price_id = null;
            new_state.manual_product_description = '';
            new_state.manual_product_note = '';
            new_state.manual_product_picture = null;
            new_state.manual_product_address_pickup = '';
            new_state.manual_product_station_name_pickup = '';
            new_state.manual_product_address_return = '';
            new_state.manual_product_station_name_return = '';
            new_state.manual_product_vehicle_code = '';
            new_state.manual_product_one_way_fees = '';
            new_state.manual_product_one_way_fees_currency = null;
            new_state.manual_product_is_hidden_for_traveler_count_price = false;
            new_state.manual_product_provider_id = null;
            return new_state;
        }
        case 'CART_MANUAL_PRODUCT_DELETE_BY_ID': {
            let cart = [...new_state.manual_item_list];
            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === action.payload) {
                    cart.splice(i, 1);
                    break;
                }
            }
            new_state.manual_item_list = cart;
            return new_state;
        }
        case 'CART_REFRESH_PRICE': {
            new_state.refresh_price = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_APPLY_MARGIN': {
            let refresh_price = { ...new_state.refresh_price };
            let type = { ...refresh_price[action.payload.key] };
            let data = { ...type[action.payload.id] };
            data.affect_to_margin = !data.affect_to_margin;
            if (!data.affect_to_margin) {
                new_state.refresh_apply_to_margin_to_all = false;
            }
            type[action.payload.id] = data;
            refresh_price[action.payload.key] = type;
            new_state.refresh_price = refresh_price;
            return new_state;
        }
        case 'CART_APPLY_TO_MARGIN_TO_ALL_PRODUCT': {
            let refresh_price = { ...new_state.refresh_price };
            refresh_price.accommodations = genericApplyToMargin('accommodations', refresh_price, !new_state.refresh_apply_to_margin_to_all);
            refresh_price.activity = genericApplyToMargin('activity', refresh_price, !new_state.refresh_apply_to_margin_to_all);
            refresh_price.cars = genericApplyToMargin('cars', refresh_price, !new_state.refresh_apply_to_margin_to_all);
            refresh_price.flight = genericApplyToMargin('flight', refresh_price, !new_state.refresh_apply_to_margin_to_all);
            new_state.refresh_apply_to_margin_to_all = !new_state.refresh_apply_to_margin_to_all;
            new_state.refresh_price = refresh_price;
            return new_state;
        }
        case 'CART_TOGGLE_MAKE_NEW_VERSION': {
            let refresh_price = { ...new_state.refresh_price };
            refresh_price.make_new_version = !refresh_price.make_new_version;
            new_state.refresh_price = refresh_price;
            return new_state;
        }
        case 'CART_SET_QUOTATION_TARGET': {
            new_state.quotation_target = action.payload;
            return new_state;
        }
        case 'CART_SET_QUOTATION_TYPE': {
            new_state.quotation_type = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_QUOTATION_CATEGORY_PRICE_DETAIL': {
            new_state.quotation_category_price_detail = !new_state.quotation_category_price_detail;
            return new_state;
        }
        case 'CART_TOGGLE_QUOTATION_PRODUCT_PRICE_DETAIL': {
            new_state.quotation_product_price_detail = !new_state.quotation_product_price_detail;
            return new_state;
        }
        case 'CART_TOGGLE_QUOTATION_PRACTICAL_INFORMATION': {
            new_state.quotation_practical_information = !new_state.quotation_practical_information;
            return new_state;
        }
        case 'CART_TOGGLE_QUOTATION_WITHOUT_PRICES': {
            new_state.quotation_without_prices = !new_state.quotation_without_prices;
            return new_state;
        }
        case 'CART_SET_QUOTATION_TARGET_LANGUAGE': {
            new_state.quotation_target_language = action.payload;
            return new_state;
        }
        case 'CART_TOGGLE_QUOTATION_CGV': {
            new_state.quotation_cgv = !new_state.quotation_cgv;
            return new_state;
        }
        case 'CART_NEED_RECALC': {
            new_state.need_recalc = true;
            return new_state;
        }
        case 'CART_RESET_RECALC': {
            new_state.need_recalc = false;
            return new_state;
        }
        case 'CART_UPDATE_CART': {
            let cart = [...new_state.assistance_cart];
            for (let i = 0; i < cart.length; i++) {
                action.payload.map(item => {
                    item.map(new_poi => {
                        if (cart[i].id === new_poi.id) {
                            cart[i] = addProviderDates(new_poi);
                        }
                    });
                });
            }
            new_state.assistance_cart = cart;
            return new_state;
        }
        case 'CART_GLOBAL_MARGIN_VALUE': {
            new_state.global_margin_value = action.payload.margin;
            return new_state;
        }
        case 'CART_GLOBAL_MARGIN_TOTAL': {
            new_state.global_margin = action.payload.margin;
            new_state.tva_value = action.payload.tva_value;
            new_state.margin_value = action.payload.margin_value;
            new_state.total_accommodations = action.payload.accommodations;
            new_state.total_cars = action.payload.cars;
            new_state.total_pois = action.payload.pois;
            new_state.total_assistances = action.payload.assistances;
            new_state.total_transfers = action.payload.transfers;
            new_state.total_flights = action.payload.flights;
            new_state.total_manual_products = action.payload.manual_products;
            new_state.has_not_cancellable_items = action.payload.has_not_cancellable_items;
            return new_state;
        }
        case ADD_CUSTOM_PICTURES: {
            return { ...new_state, custom_pictures: [...new_state.custom_pictures, ...action.payload] };
        }
        case DELETE_CUSTOM_PICTURE: {
            let custom_pictures = [...state.custom_pictures];
            custom_pictures.splice(action.payload, 1);
            return { ...new_state, custom_pictures: custom_pictures };
        }
        case DELETE_ALL_CUSTOM_PICTURE: {
            let custom_pictures = [];
            let provider_pictures = [];
            let order_pictures = [];
            return { ...new_state, custom_pictures: custom_pictures, provider_pictures: provider_pictures, order_pictures: order_pictures };
        }
        case ADD_PROVIDER_PICTURES: {
            return { ...new_state, provider_pictures: [...new_state.provider_pictures, ...action.payload] };
        }
        case ADD_CUSTOM_PICTURES_ORDER: {
            return { ...new_state, order_pictures: [...new_state.order_pictures, ...action.payload] };
        }
        case TOGGLE_PICTURE_ACTIVATED: {
            const provider_pictures = [...state.provider_pictures];
            const picture = { ...provider_pictures[action.payload] };
            picture.activated = !picture.activated;
            provider_pictures[action.payload] = picture;
            return { ...state, provider_pictures: provider_pictures };
        }
        case TOGGLE_PICTURE_SELECT_ALL: {
            let provider_pictures = [...state.provider_pictures];
            provider_pictures.map((picture) => {
                picture.activated = true;
            });
            return { ...state, provider_pictures: provider_pictures };
        }
        case TOGGLE_PICTURE_UNSELECT_ALL: {
            let provider_pictures = [...state.provider_pictures];
            provider_pictures.map((picture) => {
                picture.activated = false;
            });
            return { ...state, provider_pictures: provider_pictures };
        }
        case UPDATE_PICTURE_ORDER: {
            const { drag_index, hover_index } = action.payload;
            let order_pictures = [...state.order_pictures];
            order_pictures.splice(drag_index, 1);
            order_pictures.splice(hover_index, 0, state.order_pictures[drag_index]);
            return { ...state, order_pictures: order_pictures };
        }
        case 'CART_TOGGLE_ASSISTANCE': {
            return {
                ...state,
                assistance_type: action.payload ? action.payload.type : 0,
                assistance_modal: !state.assistance_modal,
                assistance_pax: action.payload?.travelers_ids ? action.payload.travelers_ids : [],
                assistance_package: 'Plus',
                assistance_option_pocket_wifi: false,
                pocket_wifi_pickup: '',
                pocket_wifi_physical_address: '',
                assistance_index: 0,
                assistance_baggage_protection: false,
                assistance_pocket_wifi: false,
                assistance_prices: null
            };
        }
        case 'CART_TOGGLE_ASSISTANCE_TRAVELER': {
            const assistance_pax = [...state.assistance_pax];
            if (assistance_pax.includes(action.payload)) {
                assistance_pax.splice(assistance_pax.indexOf(action.payload), 1);
            } else {
                assistance_pax.push(action.payload);
            }
            return { ...state, assistance_pax: assistance_pax };
        }
        case 'CART_TOGGLE_ASSISTANCE_VIP': {
            return { ...state, assistance_vip: !state.assistance_vip };
        }
        case 'CART_ASSISTANCE_PACKAGE': {
            return { ...state, assistance_package: action.payload };
        }
        case 'CART_SET_ASSISTANCE_INDEX': {
            return { ...state, assistance_index: action.payload };
        }
        case 'CART_SET_ASSISTANCE_PRICES': {
            return { ...state, assistance_prices: action.payload };
        }
        case 'CART_SET_ASSISTANCE_OPTION_POCKET_WIFI': {
            return { ...state, assistance_option_pocket_wifi: action.payload };
        }
        case 'CART_SET_ASSISTANCE_OPTION_POCKET_WIFI_PICKUP': {
            return { ...state, pocket_wifi_pickup: action.payload };
        }
        case 'CART_SET_ASSISTANCE_OPTION_POCKET_WIFI_PHYSICAL_ADDRESS': {
            return { ...state, pocket_wifi_physical_address: action.payload };
        }
        case 'CART_ASSISTANCE_ADD_TO_CART': {
            const assistance_cart = [...state.assistance_cart];
            assistance_cart.push(action.payload);
            return {
                ...state,
                assistance_type: 0,
                assistance_modal: false,
                assistance_pax: [],
                assistance_package: '',
                assistance_index: 0,
                assistance_baggage_protection: false,
                assistance_pocket_wifi: false,
                assistance_prices: null,
                assistance_cart: assistance_cart
            };
        }
        case 'CART_ASSISTANCE_EDIT_BY_ID': {
            const assistance_cart = [...state.assistance_cart];
            for (let i = 0; i < assistance_cart.length; i++) {
                if (assistance_cart[i].id === action.payload.id) {
                    assistance_cart[i] = action.payload;
                    break;
                }
            }
            return { ...state, assistance_cart: assistance_cart };
        }
        case 'CART_ASSISTANCE_DELETE_BY_ID': {
            const assistance_cart = [...state.assistance_cart];
            for (let i = 0; i < assistance_cart.length; i++) {
                if (assistance_cart[i].id === action.payload) {
                    assistance_cart.splice(i, 1);
                    break;
                }
            }
            return { ...state, assistance_cart: assistance_cart };
        }
        case 'CART_ASSISTANCE_SET_CART': {
            return { ...state, assistance_cart: action.payload?.map((item) => addProviderDates(item)) ?? [] };
        }
        case 'CART_MANUAL_PRODUCT_SET_ROOMS': {
            return { ...state, manual_product_rooms: action.payload };
        }
        case 'CART_UPDATE_DATE': {
            return { ...state, update_date: action.payload };
        }
        case 'CART_FILTERS_BOOKED_ITEMS': {
            return { ...state, filters_booked_items: action.payload };
        }
        case 'CART_FILTERS_WAITING_BOOKED_ITEMS': {
            return { ...state, filters_waiting_booked_items: action.payload };
        }
        case 'CART_FILTERS_NON_BOOKED_ITEMS': {
            return { ...state, filters_non_booked_items: action.payload };
        }
        case 'CART_FILTERS_MASKED_ITEMS': {
            return { ...state, filters_masked_items: action.payload };
        }
        case 'CART_FILTERS_VISIBLE_ITEMS': {
            return { ...state, filters_visible_items: action.payload };
        }
        case 'CART_FILTERS_CANCELLABLE_ITEMS': {
            return { ...state, filters_cancellable_items: action.payload };
        }
        case 'CART_FILTERS_NON_CANCELLABLE_ITEMS': {
            return { ...state, filters_non_cancellable_items: action.payload };
        }
        case 'CART_FILTERS_SELECT_ALL': {
            return {
                ...state,
                filters_booked_items: action.payload,
                filters_waiting_booked_items: action.payload,
                filters_non_booked_items: action.payload,
                filters_masked_items: action.payload,
                filters_visible_items: action.payload,
                filters_cancellable_items: action.payload,
                filters_non_cancellable_items: action.payload
            };
        }
        case 'CART_TOGGLE_PACKAGE_MODAL': {
            return { ...state, package_modal: !new_state.package_modal };
        }
        case 'CART_SET_PACKAGE_PRICE_FLIGHT': {
            let package_price = { ...action.payload };
            return { ...state, package_price_flight: package_price };
        }
        case 'CART_SET_PACKAGE_PRICE_TERRESTRIAL': {
            let package_price = { ...action.payload };
            return { ...state, package_price_terrestrial: package_price };
        }
        case 'CART_SET_PACKAGE_PRICES': {
            return {
                ...state,
                package_price_flight: action.payload.package_price_flight,
                trip_package_price_flight: action.payload.package_price_flight,
                package_price_terrestrial: action.payload.package_price_terrestrial,
                trip_package_price_terrestrial: action.payload.package_price_terrestrial,
                package_provider: action.payload.package_provider
            };
        }
        case 'CART_SET_PURCHASE_CURRENCY_ERROR': {
            return { ...state, purchase_currency_error: action.payload };
        }
        case 'CART_SET_HAS_LOCKED_ITEMS': {
            return { ...state, hasLockedItems: action.payload };
        }
        case 'CART_SET_HAS_STACKED_ITEMS': {
            return { ...state, hasStackedItems: action.payload };
        }
        case 'CART_SET_PACKAGE_PRICE_TYPE': {
            return { ...state, price_type: action.payload };
        }
        case 'CART_SET_PACKAGE_PROVIDER': {
            return { ...state, package_provider: action.payload };
        }
        case 'CART_TOGGLE_AUTOMATIC_PRODUCTS_MODAL': {
            return { ...state, automatic_products_open: !new_state.automatic_products_open };
        }
        case 'CART_SET_PACKAGE_PROVIDER_ERROR': {
            return { ...state, package_provider_error: action.payload };
        }
        case 'CART_TOGGLE_VARIANTS_HANDLE': {
            return { ...state, variant_modal: !new_state.variant_modal };
        }
        case 'CART_SET_MANUAL_POI': {
            return { ...state, manual_product_poi: !new_state.manual_product_poi };
        }
        case 'CART_SET_MANUAL_POI_TYPE': {
            return { ...state, manual_product_poi: action.payload };
        }
        case 'CART_UPDATE_VARIANTE_LOCALE': {
            const { id, value } = action.payload;
            const variant_locales = { ...state.variant_locales };
            variant_locales[id] = value;
            return { ...state, variant_locales: variant_locales };
        }
        case 'CART_UPDATE_ADDRESS_PICKUP': {
            return { ...state, manual_product_address_pickup: action.payload };
        }
        case 'CART_UPDATE_ADDRESS_RETURN': {
            return { ...state, manual_product_address_return: action.payload };
        }
        case 'CART_UPDATE_STATION_PICKUP': {
            return { ...state, manual_product_station_name_pickup: action.payload };
        }
        case 'CART_UPDATE_STATION_RETURN': {
            return { ...state, manual_product_station_name_return: action.payload };
        }
        case 'CART_UPDATE_VEHICLE_CODE': {
            return { ...state, manual_product_vehicle_code: action.payload };
        }
        case 'CART_UPDATE_ONE_WAY_FEES': {
            return { ...state, manual_product_one_way_fees: action.payload };
        }
        case 'CART_UPDATE_ONE_WAY_FEES_CURRENCY': {
            return { ...state, manual_product_one_way_fees_currency: action.payload };
        }
        case 'CART_SEE_NEW_PRICE_MODAL': {
            const { see_new_price, data } = action.payload;
            return { ...state, see_new_price: see_new_price, new_price_data: data };
        }
        case 'CART_SET_PRODUCT_TYPE_FILTERS': {
            return { ...state, product_type_filters: action.payload };
        }
        case 'CART_SET_NEW_MANUAL_PRODUCT_LIST': {
            return { ...state, new_manual_product_list: action.payload };
        }
        case 'CART_SET_ASSISTANCE_TRAVELERS_TYPE': {
            return { ...state, assistance_travelers_type: action.payload };
        }
        case 'CART_SET_DELETED_MANUAL_PRODUCT_LIST': {
            return { ...state, deleted_manual_product_list: action.payload };
        }
        case SET_PICTURE: {
            return {
                ...state,
                custom_pictures: state.custom_pictures?.map((picture) => {
                    if (isObject(action.payload) && picture.id === action.payload.id) {
                        return action.payload;
                    }
                    return picture;
                }) ?? []
            };
        }
    }
    return new_state;
}
