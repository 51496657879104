import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useExtraErrorMessage } from "./extractErrorMessage";
import { addModalErrorMessage } from "../pages/redux/circuitSlice";

type Callback = (error: Error, showInModal?: boolean) => void

export function useShowError(): Callback {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const extract = useExtraErrorMessage();

    return (error, showInModal) => {
        function showMessage(value: string) {
            if (showInModal) {
                dispatch(
                    addModalErrorMessage({
                        key: Math.random().toString(),
                        message: value
                    })
                );
            } else {
                enqueueSnackbar(value, { variant: 'error' });
            }
        }

        showMessage(extract(error));
        console.error(error);
    };
}
