import { MenuItems } from "../pages/menuItems";
import BeachComberLogo from "../../Img/cms/beach-comber-logo.svg";
import BeachComberFooterLogo from "../../Img/cms/beach-comber-footer-logo.svg";
import { Page } from "../../Components/pages/objects/page";

const Header: Page['header'] = {
    logo: BeachComberLogo,
    transparentLogo: BeachComberFooterLogo,
    infoText: {
        content: [
            {
                locale: 1,
                value: `
                    Réservation : +33(0)1 44 94 72 72
                    <br />
                    reservation@beachcombertours.fr
                `
            }
        ]
    },
    menu: {
        links: MenuItems
    }
}

export default Header;