import axios from "axios";

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
            case 429: {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        if (error.config) {
                            resolve(axios(error.config));
                        } else {
                            reject(error);
                        }
                    }, 3000);
                })
            }
        }
    }
    return Promise.reject(error);
});
