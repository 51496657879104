import BonogoLogo from "../../Img/cms/bonogo.png";
import BonogoFooterLogo from "../../Img/cms/bonogo.png";
import { MenuItems } from "../pages/menuItems";
import { Page } from "../../Components/pages/objects/page";

const Header: Page['header'] = {
    logo: BonogoLogo,
    transparentLogo: BonogoFooterLogo,
    forceTransparent: true,
    menu: {
        links: MenuItems
    }
}

export default Header;