import React from "react";
import ReactDOM from "react-dom";
import { Backdrop, CircularProgress, styled } from "@mui/material";
import { BeachBackdrop } from "../pages/beachBackdrop";

type Props = {
    open: boolean,
    children?: JSX.Element | JSX.Element[]
}

export function LoadingBackDrop(props: Props): JSX.Element | null {
    if (!props.children) {
        return (
            <BeachBackdrop open={props.open} />
        );
    }

    const app = document.getElementById("app");
    if (props.open && app) {
        return ReactDOM.createPortal(
            <CustomBackdrop
                open
                onClick={(event) => event.stopPropagation()}
            >
                {props.children ?? <CircularProgress />}
            </CustomBackdrop>,
            app
        );
    }
    return null;
}

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
    "zIndex": theme.zIndex.tooltip + 2,
    '& *': {
        color: theme.palette.primary.main
    }
}));
