import { Moment } from "moment";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Destination } from "../../Itinerary/objects/destination";
import { IataAirport } from "../../Itinerary/objects/iataAirport";
import { MinimalIataAirport } from "../../Itinerary/objects/minimalIataAirport";
import { MinimalStation } from "../../Itinerary/objects/minimalStation";

export enum DestinationTab {
    STAYS,
    PACKAGES,
    CUSTOM,
    CIRCUITS,
    AUTOTOURS,
    CRUISES,
    COMBINED,
    ALL
}

type State = {
    destination: Destination | null,
    destinations: Destination[] | null,
    filters: {
        airport: IataAirport | MinimalIataAirport | MinimalStation | null,
        departureDate: Moment | null,
        arrivalDate: Moment | null,
        nights: number | null,
        category: number | null,
        search: string | null,
        tab: DestinationTab,
        homeTab: DestinationTab,
    },
    view: 'card' | 'list'
}

const initialState: State = {
    destination: null,
    destinations: null,
    filters: {
        airport: null,
        category: null,
        departureDate: null,
        arrivalDate: null,
        nights: -1,
        search: null,
        tab: DestinationTab.ALL,
        homeTab: DestinationTab.STAYS
    },
    view: 'card'
}

const slice = createSlice({
    name: 'destination',
    initialState,
    reducers: {
        resetFilters: (state): void => {
            state.filters = initialState.filters;
        },
        setDestination: (
            state,
            action: PayloadAction<Destination | null>
        ) => {
            state.destination = action.payload
        },
        setDestinations: (
            state,
            action: PayloadAction<State['destinations']>
        ) => {
            state.destinations = action.payload
        },
        setFilter<K extends keyof State['filters']>(
            state: State,
            action: PayloadAction<{ key: K, value: State['filters'][K] }>
        ): void {
            state.filters[action.payload.key] = action.payload.value;
        },
        setView: (
            state,
            action: PayloadAction<State['view']>
        ) => {
            state.view = action.payload
        },
    }
});

export default slice;

export const {
    resetFilters,
    setDestination,
    setDestinations,
    setFilter,
    setView
} = slice.actions;
