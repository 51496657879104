import React, { useEffect, useReducer } from "react";
import { Box, keyframes, styled } from "@mui/material";
import { uniqueId } from "lodash";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { getHeaderContent } from "../../mocks/pages/headerHardCodedContent";
import Logo from "../../Img/cms/beach-comber-plain-logo.gif";

type Props = {
    open?: boolean,
    main?: boolean
}

const pool: string[] = [];

export function BeachBackdrop(props: Props): React.ReactNode {
    const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
    const logo = JSON.parse(localStorage.getItem('config') ?? '{}').logo;
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    useEffect(() => {
        if (!props.main && props.open) {
            let timeOnStart = Date.now();
            const id = uniqueId();
            pool.push(id);
            const event = new CustomEvent('beach-backdrop-change');
            document.dispatchEvent(event);
            return () => {
                function onClose() {
                    const index = pool.indexOf(id);
                    if (index >= 0) {
                        pool.splice(index, 1);
                        const event = new CustomEvent('beach-backdrop-change');
                        document.dispatchEvent(event);
                    }
                }

                const DELAY = 3000;
                if (Date.now() - timeOnStart < DELAY) {
                    setTimeout(() => {
                        onClose();
                    }, DELAY - (Date.now() - timeOnStart));
                } else {
                    onClose();
                }
            };
        }
    }, [props.main, props.open]);

    useEffect(() => {
        if (props.main) {
            const handler = () => {
                forceUpdate();
            }
            document.addEventListener('beach-backdrop-change', handler);
            return () => document.removeEventListener('beach-backdrop-change', handler);
        }
    }, [props.main]);

    if (!props.main || pool.length === 0) {
        return null;
    }

    if (quotationCode === 'beachcomber') {
        return (
            <LoadingBackDrop open>
                <Box sx={{ position: 'relative' }}>
                    <Box
                        src={Logo}
                        sx={{
                            width: '300px',
                            height: 'auto',
                        }}
                        component="img"
                    />
                </Box>
            </LoadingBackDrop>
        );
    }

    return (
        <LoadingBackDrop open>
            <Box sx={{ position: 'relative' }}>
                <Box
                    src={
                        (() => {
                            const fallbackUrl = logo?.url ??
                                logo?.thumbnail_big ??
                                logo?.thumbnail_little ??
                                logo?.thumbnail_medium ??
                                '';
                            return getHeaderContent().logo ?? fallbackUrl
                        })()
                    }
                    sx={{
                        width: '130px',
                        height: 'auto',
                        maxHeight: '75vh'
                    }}
                    component="img"
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 'calc(50% + 50px)',
                        left: 'calc(50% - 10px)'
                    }}
                >
                    <Loader />
                </Box>
            </Box>
        </LoadingBackDrop>
    );
}

const Loader = styled('span')(() => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    display: 'block',
    margin: '15px auto',
    position: 'relative',
    color: '#fff',
    boxSizing: 'border-box',
    animation: `${Animation} 1s linear infinite alternate`
}));

const Animation = keyframes(`
    0% {
        box-shadow: -62px -20px ,  -22px 0,  22px 0, 62px 0;
    }
    33% {
        box-shadow: -62px 0px, -22px -20px,  22px 0, 62px 0;
    }
    66% {
        box-shadow: -62px 0px , -22px 0, 22px -20px, 62px 0;
    }
    100% {
        box-shadow: -62px 0 , -22px 0, 22px 0 , 62px -20px;
    }
`);
